import React from 'react';
import styled from "styled-components"
let Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: 'Comfortaa';
`
let BlackSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #000000;
  height: 200px;
  width: 350px;
  border-radius: 20px;
  color: #FFFFFF;
  text-align: center;
  padding: 20px;
  margin: 50px 0 25px;
`
let BigText = styled.div`
  font-weight: bold;
  font-size: 144px;
`
let Title = styled.div`
  font-weight: normal;
  font-size: 24px;
  max-width: 40%;
`
let Subtitle = styled.div`
  width: 350px;
  font-size: 24px;
  font-weight: 300;
`
const Ecomm3Results = (props) => {
  let ecomm3Results = props.data.nodes.map((info)=>{
    return (
      <Result key={(info.title).replace(/\W/g, '').substring(0, 10)}>
        <BlackSquare>
          <BigText>
            {info.bigText}
          </BigText>
          <Title>
            {info.title}
          </Title>
        </BlackSquare>
        <Subtitle>
          {info.subtitle}
        </Subtitle>
      </Result>
    )
  });
  return (
    <React.Fragment>
      {ecomm3Results}
    </React.Fragment>
  );
};
export default Ecomm3Results;